import React from "react";
import Layout from "../../Components/Layout";
import { useSelector } from "react-redux";
import DashboardBottom from "../../Components/Auth/DashboardBottom";
import DashboardSidebar from "../../Components/Auth/DashboardSidebar";
import DashboardRightBar from "../../Components/Auth/DashboardRightBar";
import DashboardTop from "../../Components/Auth/DashboardTop";

const Dashboard = () => {

    const loginState = useSelector((state: any) => state.loginState.data.user);

    return (
        <>
            <Layout>
                <nav className="bg-[#178285] fixed w-full top-0 left-0 py-3 z-20">
                    <div className="container">
                        <div className="flex items-center justify-between">
                            <DashboardSidebar loginState={ loginState } />
                            <DashboardRightBar />
                        </div>
                    </div>
                </nav>

                <DashboardTop />
                <DashboardBottom />
            </Layout>
        </>
    );
}

export default Dashboard;