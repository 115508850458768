

import { Dispatch } from 'redux';
import { AxiosGetBankDetail } from '../../Utilities/axios';
import { fetchGetBankFailure, fetchGetBankRequest, fetchGetBankSuccess } from '../actions/GetBankAvtion';

// export const fetchGetBankData = () => {
//     return async (dispatch: Dispatch) => {
//         dispatch(fetchGetBankRequest());
//         try {
//             const response = await AxiosGetBankDetail.get('');
//             dispatch(fetchGetBankSuccess(response.data.data));
//         } catch (error: any) {
//             dispatch(fetchGetBankFailure(error.message || 'Failed to fetch getbank data'));
//         }
//     };
// };
export const fetchGetBankData = () => {
    return async (dispatch: Dispatch) => {
        const id = localStorage.getItem("loginUserId");
        if (!id) {
            console.error("No loginUserId found in localStorage");
            return;
        }

        dispatch(fetchGetBankRequest());
        try {
            const response = await AxiosGetBankDetail.get(`?id=${id}`);
            dispatch(fetchGetBankSuccess(response.data.data));
        } catch (error: any) {
            dispatch(fetchGetBankFailure(error.message || 'Failed to fetch getbank data'));
        }
    };
};
