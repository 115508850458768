import './app.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import BrowserRoute from "./Routes/Routes";
import { Provider } from "react-redux";
import { store, persist } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const stripePromise = loadStripe('pk_test_51LKhaFBpoiqesGBKbkxdcB2zDkPe7u1tILJtxQMwB61VBZ5VEQlG4abos2VJXF62Ybe4ns2lt7ZGwxFL9KJner5R00SIMfTIuQ');

root.render(
    <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persist }>
        <Elements stripe={stripePromise}>
            <BrowserRoute />
            </Elements>
        </PersistGate>
    </Provider>
);